export class Competition {
  id: number
  seasonID: number
  season: string
  eventID: number
  event: string
  eventNickname: string
  enddate: Date
  enddateSubscription: number

  constructor() {
      this.id = 0;
      this.seasonID = 0
      this.season = "";
      this.eventID = 0
      this.event = ""
      this.eventNickname = ""
      this.enddate = new Date()
      this.enddateSubscription = 0
  }
}
