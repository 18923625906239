import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticateGuard } from './guards/authenticate.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'recovery',
    loadChildren: () => import('./recovery/recovery.module').then( m => m.RecoveryPageModule)
  },
  {
    path: 'competitions',
    loadChildren: () => import('./competitions/competitions.module').then( m => m.CompetitionsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'registration/:id',
    loadChildren: () => import('./rider-registration/rider-registration.module').then( m => m.RiderRegistrationPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'competition-overview/:id',
    loadChildren: () => import('./competition-overview/competition-overview.module').then( m => m.CompetitionOverviewPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'competition-details/:id',
    loadChildren: () => import('./competition-details/competition-details.module').then( m => m.CompetitionDetailsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'teams',
    loadChildren: () => import('./teams/teams.module').then( m => m.TeamsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'ranking',
    loadChildren: () => import('./ranking/ranking.module').then( m => m.RankingPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'results',
    loadChildren: () => import('./results/results.module').then( m => m.ResultsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'new-competition',
    loadChildren: () => import('./new-competition/new-competition.module').then( m => m.NewCompetitionPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'enter-results',
    loadChildren: () => import('./enter-results/enter-results.module').then( m => m.EnterResultsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'substitutions',
    loadChildren: () => import('./substitutions/substitutions.module').then( m => m.SubstitutionsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'rules',
    loadChildren: () => import('./rules/rules.module').then( m => m.RulesPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'participation/:seasonEventID/:seasonEventRaceID/:userID',
    loadChildren: () => import('./participation/participation.module').then( m => m.ParticipationPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'user-details',
    loadChildren: () => import('./user-details/user-details.module').then( m => m.UserDetailsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'uci-ranking',
    loadChildren: () => import('./uci-ranking/uci-ranking.module').then( m => m.UciRankingPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'new-rider',
    loadChildren: () => import('./new-rider/new-rider.module').then( m => m.NewRiderPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./subscriptions/subscriptions.module').then( m => m.SubscriptionsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'manage-race',
    loadChildren: () => import('./manage-race/manage-race.module').then( m => m.ManageRacePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'manage-rules',
    loadChildren: () => import('./modify-rules/modify-rules.module').then( m => m.ModifyRulesPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: "**",
    redirectTo: "login"
  },
  {
    path: 'modify-rules',
    loadChildren: () => import('./modify-rules/modify-rules.module').then( m => m.ModifyRulesPageModule)
  }
];

@NgModule({
  imports: [
    //RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
