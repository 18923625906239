import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { UserCredentials } from '../classes/usercredentials'

interface userLoginResponse {
  success: boolean,
  message: string,
  user: UserCredentials,
  token: string
}

interface userRecoveryResponse {
  success: boolean,
  message: string,
}

interface userResetPasswordResponse {
  success: boolean,
  message: string,
}

interface userResetUsernameResponse {
  success: boolean,
  message: string,
}

interface logoutStatus {
  success: boolean
}

// CHANGE FOR WEBSITE
//const url: string = 'http://localhost:8080/api/'
const url: string = 'https://www.deloopingprono.be/api/'
//const url: string = 'http://synologynas:8080/api/'
export { url }

@Injectable({
  providedIn: 'root'
})
export class UserService {

  loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || 'false');
  explicitLogin: boolean = false
  user: UserCredentials

  constructor(
    private http: HttpClient
    ) {
      this.user = new UserCredentials()

      this.isLoggedIn()
    }

    getUser() {
      return this.user
    }

    isLoggedIn() {
      if(this.explicitLogin) {
        console.log("Force to login")
        this.explicitLogin = false
        return false
      }
      // Check local storage if we are logged in
      let loggedIn = JSON.parse(localStorage.getItem('loggedIn') || this.loggedInStatus.toString());

      if(loggedIn) {
        // Load the local user if this is not known yet
        if(this.user.id == 0) {
          this.loadUserLocal()
        }
        // inform app module to update login data
        window.dispatchEvent(new CustomEvent('user:login'))
      }

      return loggedIn
    }

    setMustLogin(login: boolean) {
      this.explicitLogin = login
    }

    login(email, password) {
      return this.http.post<userLoginResponse>(url + 'login', {
        email,
        password
      });
    }

    logout() {
      return this.http.get<logoutStatus>(url + 'logout')
    }

    registerUser(email, password) {
      return this.http.post<userLoginResponse>(url + 'register', {
        email,
        password
      });
    }

    recoverPassword(email) {
      return this.http.post<userRecoveryResponse>(url + 'recover', {
        email
      });
    }

    updateUsername(email, username) {
      return this.http.post<userResetUsernameResponse>(url + 'reset_username', {
        email,
        username
      });
    }

    updatePassword(email, password) {
      return this.http.post<userResetPasswordResponse>(url + 'reset_password', {
        email,
        password
      });
    }

    loadUserLocal() {
      this.user.id = Number.parseInt(localStorage.getItem('id'))
      this.user.username = localStorage.getItem('username')
      this.user.email = localStorage.getItem('email')
      this.user.activated = Number.parseInt(localStorage.getItem('activated')) ? true : false
      this.user.admin = Number.parseInt(localStorage.getItem('admin')) ? true : false
      this.user.reset_password = Number.parseInt(localStorage.getItem('reset_password')) ? true : false
    }

    saveUserLocal(user: UserCredentials) {
      this.user = user
      localStorage.setItem('id', this.user.id.toString());
      localStorage.setItem('username', this.user.username);
      localStorage.setItem('email', this.user.email);
      localStorage.setItem('activated', this.user.activated ? '1' : '0');
      localStorage.setItem('admin', this.user.admin ? '1' : '0');
      localStorage.setItem('reset_password', this.user.reset_password ? '1' : '0');
    }

    setLoggedIn(value: boolean, user: UserCredentials, token: string) {
      this.loggedInStatus = value ? 'true' : 'false';
      localStorage.setItem('loggedIn', value ? 'true' : 'false');
      localStorage.setItem('token', token);

      /* Store user locally */
      this.saveUserLocal(user)

      if(value) {
        window.dispatchEvent(new CustomEvent('user:login'));
      } else {
        window.dispatchEvent(new CustomEvent('user:logout'));
      }
    }

    getToken() {
      return localStorage.getItem('token')
    }
}
