import { Component, OnInit, ViewChild } from '@angular/core';
//import { SwUpdate } from '@angular/service-worker';
import { Router } from '@angular/router';
import { Platform, ToastController, AlertController, IonMenu  } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Network } from '@ionic-native/network/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx'

import { TranslateService } from '@ngx-translate/core';

import { UserService } from './services/user.service'
import { DatabaseService } from './services/database.service'
import { UserCredentials } from './classes/usercredentials'
import { SettingsService } from './services/settings.service'
import { UpdateService } from './services/update.service'
import { HttpEventType } from '@angular/common/http';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Competitions',
      url: '/competitions',
      icon: 'calendar-outline',
      iconstyle: 'ionic',
      adminlevel: 0
    },
    {
      title: 'UCI_ranking',
      url: '/uci-ranking',
      icon: 'ribbon-outline',
      iconstyle: 'ionic',
      adminlevel: 0
    },
    {
      title: 'Settings',
      url: '/settings',
      icon: 'settings-outline',
      iconstyle: 'ionic',
      adminlevel: 0
    },
    {
      title: 'Rules',
      url: '/rules',
      icon: 'information-circle-outline',
      iconstyle: 'ionic',
      adminlevel: 0
    },
    {
      title: 'Admin',
      url: '/admin',
      icon: 'lock-closed-outline',
      iconstyle: 'ionic',
      adminlevel: 1
    },
    {
      title: 'Logout',
      url: '/logout',
      icon: 'log-out-outline',
      iconstyle: 'ionic',
      adminlevel: 0
    }
  ];

  @ViewChild('menuBar') menuBarElement: IonMenu;
  loggedIn = false;
  user: UserCredentials = new UserCredentials();
  isNetworkConnected: boolean = true
  connectSubscription: any
  disconnectSubscription: any
  toastNetworkConnection: any = null
  software_version: string = "1.0.12"
  majorServerVersion: number = -1
  midServerVersion: number = -1
  minorServerVersion: number = -1
  serverVersionHigher: boolean = false
  downloadProgressPercent: number = 0
  downloadProgress: number = 0
  progressBarType: string = "determinate"
  showProgressbar: boolean = false
  showDownloadButton: boolean = false
  newVersionAppPath: string = ""

  constructor(
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private userService: UserService,
    private databaseService: DatabaseService,
    //private swUpdate: SwUpdate,
    private toastController: ToastController,
    private alertController: AlertController,
    private updateService: UpdateService,
    private network: Network,
    private oneSignal: OneSignal,
    private fileOpener: FileOpener
  ) {
    this.initializeApp();
    this.translate.setDefaultLang('nl');
  }

  async ngOnInit() {
    this.listenForLoginEvents()
    this.listenForNetworkConnection()
    this.listenForNetworkDisconnection()
    if(this.platform.is("android") || this.platform.is("electron")) {
      this.checkSoftwareVersion()
    }
  }

  ionViewDidEnter() {

  }

  initializeApp() {
    this.splashScreen.show()
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      //if(this.platform.is('capacitor') || this.platform.is('cordova')) {
      if(this.platform.is("android")) {
        this.setupPush()
      }

      this.platform.pause.subscribe(async () => {
        console.log("Pause app");
      })

      this.platform.resume.subscribe(async () => {
        console.log("Resume app");
      })
    });
  }

  setupPush() {
    //Remove this method to stop OneSignal Debugging
    this.oneSignal.setLogLevel({logLevel: 6, visualLevel: 0})

    this.oneSignal.startInit("7e3a6b2b-417b-4b91-bc8d-40e0a18d7642", "823637784090");

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None)

    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let title = data.payload.title
      let msg = data.payload.body
      let addidionalData = data.payload.additionalData.seasonEventRaceID
      this.showAlert(title, msg, addidionalData)
      // Possible: redirect to current processed event
    })

    this.oneSignal.handleNotificationOpened().subscribe(data => {
      let title = data.notification.payload.title
      let msg = data.notification.payload.body
      let additionalData = data.notification.payload.additionalData
      this.showAlert(title, msg, additionalData)
    })

    this.oneSignal.endInit();
  }

  checkSoftwareVersion() {
    this.updateService.getCurrentVersion().subscribe({next: this.handleCheckSoftwareVersion.bind(this), error: this.handleCheckSoftwareVersionError.bind(this)})
  }

  handleCheckSoftwareVersion(data) {
    let version = data.version
    let splittedServerVersion = version.split(".")
    if(splittedServerVersion.length == 3) {
      this.majorServerVersion = Number(splittedServerVersion[0])
      this.midServerVersion = Number(splittedServerVersion[1])
      this.minorServerVersion = Number(splittedServerVersion[2])
    }

    let splittedCurrentVersion = this.software_version.split(".")
    let majorCurrentVersion = 0
    let midCurrentVersion = 0
    let minorCurrentVersion = 0
    if(splittedCurrentVersion.length == 3) {
      majorCurrentVersion = Number(splittedCurrentVersion[0])
      midCurrentVersion = Number(splittedCurrentVersion[1])
      minorCurrentVersion = Number(splittedCurrentVersion[2])
    }

    // Check current version with version from server
    if(this.majorServerVersion != -1 && this.midServerVersion != -1 && this.minorServerVersion != -1) {
      if(this.majorServerVersion > majorCurrentVersion) {
        this.serverVersionHigher = true
      } else {
        if(this.midServerVersion > midCurrentVersion) {
          this.serverVersionHigher = true
        } else {
          if(this.minorServerVersion > minorCurrentVersion) {
            this.serverVersionHigher = true
          }
        }
      }
    }
    this.showDownloadButton = this.serverVersionHigher
    

    /*if(this.serverVersionHigher) {
      // Question the user to download the new version from the server
      this.presentAlertUpdate()
    }*/
  }

  handleCheckSoftwareVersionError(error) {
    console.log("HTTP error " + error);
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })

  downloadApp() {
    if(this.platform.is("android")) {
      this.downloadAPK()
    } else if(this.platform.is("electron")) {
      this.downloadZIP()
    }
  }

  downloadAPK() {
    this.showProgressbar = true
    this.downloadProgress = 0
    this.updateService.downloadAPK().subscribe( async event => {
      if(event.type == HttpEventType.DownloadProgress) {
        this.downloadProgressPercent = Math.round((100 * event.loaded) / event.total)
        this.downloadProgress = event.loaded / event.total
        if(event.total == undefined) {
          this.progressBarType = 'indeterminate'
        }
        //console.log("event " + event + ' loaded ' + event.loaded + ' total ' + event.total)
      } else if(event.type == HttpEventType.Response) {
        this.downloadProgressPercent = 0
        this.downloadProgress = 1
        this.progressBarType = 'determinate'

        console.log("Download complete")

        const name = 'loopingprono_' + this.majorServerVersion + '_' + this.midServerVersion + '_' + this.minorServerVersion + '.apk'
        /*const base64 = await this.convertBlobToBase64(event.body) as string
        console.log("converted to base64 done")

        const savedFile = await Filesystem.writeFile({
          path: name,
          data: base64,
          directory: FilesystemDirectory.Documents
        })

        console.log("Saved to " + savedFile.uri)

        this.newVersionAppPath = savedFile.uri*/
        // Secondly, write the blob to disk. The 'writeFile' function takes an options
        // object and returns an object containing the newly written file's URI.
        // https://www.npmjs.com/package/capacitor-blob-writer
        const { uri } = await Filesystem.writeFile({
          // The path to write the file to. The path may be specified as an absolute
          // URL (beginning with "file://") or a relative path, in which case it is
          // assumed to be relative to the 'directory' option.
          path: name,

          // The FilesystemDirectory to write the file inside. This option is required
          // unless the 'path' option begins with "file://".
          directory: Directory.Documents,

          // The data to write to the file. It MUST be a Blob.
          data: event.body,

          // Whether to create intermediate directories as required. This option
          // defaults to 'false'.
          recursive: false,

          // Whether to fallback to an alternative strategy on failure. See the
          // "Fallback mode" section below for a detailed explanation. The option may
          // either be a boolean, or a function which takes the error and returns a
          // boolean. This option defaults to 'true'.
          encoding: Encoding.UTF8,
        });
        this.newVersionAppPath = uri
        this.showProgressbar = false
        this.showDownloadButton = false

        this.fileOpener.open(this.newVersionAppPath, 'application/vnd.android.package-archive')
        .then(() => console.log('File is opened'))
        .catch(e => console.log('Error opening file', e));
      }
    }, error => { console.log("HTTP error " + error); this.showProgressbar = false })
  }

  downloadZIP() {
    this.showProgressbar = true
    this.downloadProgress = 0
    this.updateService.downloadZIP().subscribe( async event => {
      if(event.type == HttpEventType.DownloadProgress) {
        this.downloadProgressPercent = Math.round((100 * event.loaded) / event.total)
        this.downloadProgress = event.loaded / event.total
        if(event.total == undefined) {
          this.progressBarType = 'indeterminate'
          
        }
        //console.log("event " + event + ' loaded ' + event.loaded + ' total ' + event.total)
      } else if(event.type == HttpEventType.Response) {
        this.downloadProgressPercent = 0
        this.downloadProgress = 1
        this.progressBarType = 'determinate'

        console.log("Download complete")

        const name = 'LoopingProno' + this.majorServerVersion + '_' + this.midServerVersion + '_' + this.minorServerVersion + '-win32-x64.zip'
        
        const base64 = await this.convertBlobToBase64(event.body) as string
        console.log("converted to base64 done")

        const savedFile = await Filesystem.writeFile({
          path: name,
          data: base64,
          directory: Directory.Documents
        })

        console.log("Saved to " + savedFile.uri)
        this.newVersionAppPath = savedFile.uri 
        // Secondly, write the blob to disk. The 'writeFile' function takes an options
        // object and returns an object containing the newly written file's URI.
        /*const { uri } = await writeFile({
          // The path to write the file to. The path may be specified as an absolute
          // URL (beginning with "file://") or a relative path, in which case it is
          // assumed to be relative to the 'directory' option.
          path: name,

          // The FilesystemDirectory to write the file inside. This option is required
          // unless the 'path' option begins with "file://".
          directory: FilesystemDirectory.Documents,

          // The data to write to the file. It MUST be a Blob.
          data: event.body,

          // Whether to create intermediate directories as required. This option
          // defaults to 'false'.
          recursive: true,

          // Whether to fallback to an alternative strategy on failure. See the
          // "Fallback mode" section below for a detailed explanation. The option may
          // either be a boolean, or a function which takes the error and returns a
          // boolean. This option defaults to 'true'.
          fallback(writeError) {
            console.log(writeError);
            return true;
          }
        });
        this.newVersionAppPath = uri*/
        this.showProgressbar = false
        this.showDownloadButton = false
        
      }
    }, error => { console.log("HTTP error " + error); this.showProgressbar = false })
  }

  onUpdateApp() {
    this.downloadApp()
  }

  onUserClicked() {
    this.router.navigate(["/user-details"])
    this.menuBarElement.close()
  }

  async presentAlertUpdate() {
    const alert = await this.alertController.create({
      cssClass: 'update-class',
      header: this.translate.instant('New_version'),
      message: this.translate.instant('Download_new_version'),
      buttons: [
        {
          text: this.translate.instant('Cancel'),
          role: 'cancel',
          handler: () => {
            console.log('The update was cancelled.');
          }
        }, {
          text: this.translate.instant('Ok'),
          handler: () => {
            console.log("Let's do the download");
            // If answer is positive
            this.downloadApp()
          }
        }
      ]
    });

    await alert.present();
  }

  async showAlert(title, msg, additionalData) {
    const alert = await this.alertController.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'OK',
        }
      ]
    })

    await alert.present();
  }

  async presentAlertUsername() {
    const alert = await this.alertController.create({
      cssClass: 'new-username',
      header: this.translate.instant("New_username") ,
      message: this.translate.instant("New_username_message") ,
      inputs: [
        {
          name: 'username',
          type: 'text',
          placeholder: ((this.user.username == "") ? this.translate.instant('New_username') : this.user.username),
          value: this.user.username
        },
      ],
      buttons: [
        {
          text: 'OK',
          handler: (data) => {
            if(data.username != "") {
              if(data.username != this.user.username) {
                this.user.username = data.username
                this.userService.saveUserLocal(this.user)
                let users = []
                users.push(this.user)
                this.databaseService.postUserCredentials(users).subscribe(data => {
                  console.log("Reply from server " + data)
                  this.presentToastSaveMessage(data.success)
                })
              }
            }
          }
        }
      ]
    });

    await alert.present();
  }

  async presentToastSaveMessage(storeSuccess: boolean) {
    const toast = await this.toastController.create({
      color: storeSuccess ? 'success' : 'danger',
      header: this.translate.instant("Store_user"),
      message: storeSuccess ? this.translate.instant("Store_user_message_ok") : this.translate.instant("Store_user_message_nok") ,
      duration: 2000,
      position: 'bottom',
    });

    await toast.present();
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
      if(this.loggedIn) {
        this.user = this.userService.getUser()
      }
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    /*window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });*/

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  listenForNetworkConnection() {
    // watch network for a connection
    this.connectSubscription = this.network.onConnect().subscribe(() => {
      console.log('network connected!');
      // We just got a connection but we need to wait briefly before we determine the connection type. Might need to wait prior to doing any api requests as well.
      setTimeout(() => {
        console.log("network " + this.network.type)
        if (this.network.type === 'wifi') {
          console.log('we got a wifi connection, woohoo!');
        } else if(this.network.type === 'ethernet') {
          console.log('we got an etheret connection, woohoo!');
        } else if((this.network.type === '4g') || (this.network.type === '3g')) {
          console.log('we got a cellular connection, woohoo!');
        }

        if(this.toastNetworkConnection) {
          this.toastNetworkConnection.dismiss()
        }
        if(!this.isNetworkConnected) {
          this.isNetworkConnected = true
          this.presentToastNetworkConnectionMessage(true)
        }

      }, 3000);
    })
  }

  listenForNetworkDisconnection() {
    // watch network for a disconnection
    this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      console.log('network was disconnected :-(');
      if(this.toastNetworkConnection) {
        this.toastNetworkConnection.dismiss()
      }
      if(this.isNetworkConnected) {
        this.isNetworkConnected = false
        this.presentToastNetworkConnectionMessage(false)
      }
    });
  }

  async presentToastNetworkConnectionMessage(hasInternet: boolean) {
    this.toastNetworkConnection = await this.toastController.create({
      color: hasInternet ? 'success' : 'danger',
      //header: this.translate.instant("Store_user"),
      message: hasInternet ? this.translate.instant("Internet_message_ok") : this.translate.instant("Internet_message_nok") ,
      duration: hasInternet ? 2000 : 120000,
      position: 'bottom',
    });

    await this.toastNetworkConnection.present();
    //this.toastNetworkConnection = null
  }
}
