export class UserCredentials {
  id: number
  username: string
  email: string
  password: string
  activated: boolean
  admin: boolean
  reset_password: boolean

  constructor() {
    this.id = 0;
    this.username = "";
    this.email = ""
    this.password = ""
    this.activated = false
    this.admin = false
    this.reset_password = false
  }
}
