import { NgModule } from '@angular/core';

//import { LongPressDirective } from './directives/long-press.directive';


@NgModule({
  imports: [
  ],
  declarations: [
    //LongPressDirective,
  ],
  exports: [
    //LongPressDirective,
  ]
})

export class SharedModule { }
