import { Injectable } from '@angular/core';
import { Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service'
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard  {
  constructor(
    private userService: UserService,
    private router: Router,
    ) {

  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if(this.userService.isLoggedIn()) {
      if(this.userService.getUser().reset_password) {
        this.router.navigate(['reset-password'])
      } else {
        this.router.navigate(['competitions'])
      }
    } else {
      return true
    }
  }
}
