import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Season } from '../classes/season'
import { Competition } from '../classes/competition';
import { Event } from '../classes/event';
import { Rule } from '../classes/rule';
import { Race } from '../classes/race';
import { Rider, FindRider } from '../classes/rider';
import { Subscription } from '../classes/subscription'
import { ParticipantRider } from '../classes/participantrider';
import { SeasonEventRace } from '../classes/seasoneventrace';
import { Stage } from '../classes/stage';
import { Joker } from '../classes/joker';
import { RiderResult } from '../classes/riderresult';
import { TeamResult } from '../classes/teamresult';
import { ParticipationExt } from '../classes/participationext';
import { SubstitutionExt } from '../classes/substitutionext';
import { Substitution } from '../classes/substitution';
import { Team } from '../classes/team';
import { RiderResult2 } from '../classes/riderresult2'
import { UserCredentials } from '../classes/usercredentials';
import { UserService, url } from './user.service';
import { SeasonEventPoint } from '../classes/seasoneventpoint'
import { RiderResult3 } from '../classes/riderresult3';

interface usersData {
  users: []
}

interface userCredentialsData {
  usercredentials: []
}

interface SeasonInterface {
  id: number,
  name: string
}

interface seasonData {
  season: SeasonInterface
}

interface competitionsData {
  competitions: any []
}

interface competitionsData2 {
  success: boolean,
  competitions: any [],
  length: number,
  totalLength: number
}

interface timeSyncData {
  time: Date
}

interface eventsData {
  events: Event[]
}

interface rulesData {
  rules: Rule[]
}

interface raceData {
  races: Race[]
}

interface subscriptions {
  subscriptions: Subscription[]
}

interface seasonEventRaceData {
  seasonEventRaces: SeasonEventRace[]
}

interface resultData {
  results: RiderResult[]
}

interface resultTeamData {
  teams: TeamResult[]
}

interface ridersData {
  riders: Rider[]
}

interface findRiderData {
  riders: FindRider[]
}

interface teamsData {
  teams: Team[]
}

interface participantTeamData {
  participantRiders: ParticipantRider[]
}

interface participationsExtData {
  participations: ParticipationExt[]
}

interface participationExtData {
  participation: ParticipationExt[]
}

interface substitutionData {
  substitutions: SubstitutionExt[]
}

interface riderResultsData {
  riderResults: RiderResult2[]
}

interface subscriptionData {
  subscriptions: []
}

interface seasonEventPointsData {
  seasonEventPoints: SeasonEventPoint[]
}

interface seasonEventResultsData {
  success: boolean,
  riderResults: RiderResult3,
  length: number,
  totalLength: number
}


interface unprocessedSubstitutionData {
  substitutions: []
}

interface jokerData {
  jokers: Joker[]
}

interface numberSubstitionsData {
  success: boolean,
  numberSubstitutions: number
}

interface postParticipantTeam {
  success: boolean,
  timestamp: string
}

interface postUserCredentials {
  success: boolean
}

interface postSubstitution {
  success: boolean,
  timestamp: string
}

interface postSeasonEventRaces {
  success: boolean,
  error: string
}

interface postRaces {
  success: boolean,
  error: string
}

interface postRule {
  success: boolean,
  error: string
}

interface postRiderResults {
  success: boolean,
  error: string
}

interface postTeamResults {
  success: boolean,
  error: string
}

interface deleteSubstitution {
  success: boolean,
  error: string
}

interface postParticipation {
  success: boolean,
  error: string
}

interface postNotification {
  success: boolean,
  error: string
}

interface postNewRider {
  success: boolean,
  error: string,
  id: number
}

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  seasons: Season[]

  constructor(
    private userService: UserService,
    private http: HttpClient
  ) {
    this.seasons = []
  }

  getHeader() {
    let token = this.userService.getToken();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: token
      })
    };

    return httpOptions
  }

  getUsersObservable() {
    return this.http.get<usersData>(url + 'users', this.getHeader());
  }

  getUserCredentialsObservable() {
    return this.http.get<userCredentialsData>(url + 'usercredentials', this.getHeader());
  }

  getSeason(name: number) {
    return this.http.get<seasonData>(url + `season/${name}`, this.getHeader());
  }

  getCompetitionsObservable() {
    return this.http.get<competitionsData>(url + `competitions`, this.getHeader());
  }

  getCompetitionsObservable2(limit: number, offset: number) {
    return this.http.get<competitionsData2>(url + `competitions2/${limit}/${offset}`, this.getHeader());
  }

  getCompetitions() {
    return this.seasons
  }

  getCompetition(seasonEventID: number) {
    for(let season of this.seasons) {
      for(let competition of season.competitions) {
        if(competition.id == seasonEventID) {
          return competition
        }
      }
    }
    return new Competition()
  }

  setCompetitions(seasons: Season[]) {
    this.seasons = seasons
  }

  getTimeSync() {
    return this.http.get<timeSyncData>(url + `timesync`, this.getHeader());
  }

  getEventsObservable() {
    return this.http.get<eventsData>(url + `events`, this.getHeader());
  }

  getRulesObservable() {
    return this.http.get<rulesData>(url + `rules`, this.getHeader());
  }

  getRacesObservable() {
    return this.http.get<raceData>(url + `races`, this.getHeader());
  }

  getSubscriptions(seasonEventID: number) {
    return this.http.get<subscriptions>(url + `subscriptionsevent/${seasonEventID}`, this.getHeader());
  }

  getSeasonEventRacesObservable(seasonEventID: number) {
    return this.http.get<seasonEventRaceData>(url + `seasoneventraces/${seasonEventID}`, this.getHeader());
  }

  getSeasonEventRaces2Observable(season: number, event: number) {
    return this.http.get<seasonEventRaceData>(url + `seasoneventraces/${season}/${event}`, this.getHeader());
  }

  getResultsObservable(seasonEventRaceID: number) {
    return this.http.get<resultData>(url + `results/${seasonEventRaceID}`, this.getHeader());
  }

  getResultRidersObservable(seasonEventRaceID: number) {
    return this.http.get<resultData>(url + `resultriders/${seasonEventRaceID}`, this.getHeader());
  }

  getResultTeamsObservable(seasonEventRaceID: number) {
    return this.http.get<resultTeamData>(url + `resultteams/${seasonEventRaceID}`, this.getHeader());
  }

  getRidersObservable(seasonID: number) {
    return this.http.get<ridersData>(url + `riders/${seasonID}`, this.getHeader());
  }

  getTeamsObservable(seasonID: number) {
    return this.http.get<teamsData>(url + `teams/${seasonID}`, this.getHeader());
  }

  loadRiders(seasonID: number, page: number) {
    return this.http.get<ridersData>(url + `loadriders/${seasonID}/${page}`, this.getHeader());
  }

  getParticipantTeamObservable(userID: number, seasonEventID: number, seasonEventRaceID: number) {
    return this.http.get<participantTeamData>(url + `participantteam/${userID}/${seasonEventID}/${seasonEventRaceID}`, this.getHeader());
  }

  getJokersObservable(eventID: number) {
    return this.http.get<jokerData>(url + `jokers/${eventID}`, this.getHeader());
  }

  getNumberOfSubstitutions(userID: number) {
    return this.http.get<numberSubstitionsData>(url + `substitutionno/${userID}`, this.getHeader());
  }

  getUnprocessedSubstitutionsObservable(userID: number, seasonEventID: number) {
    return this.http.get<unprocessedSubstitutionData>(url + `unprocessedsubstitutions/${userID}/${seasonEventID}`, this.getHeader());
  }

  getParticipantsTeamsObservable(seasonEventID: number, seasonEventRaceID: number, page: number) {
    return this.http.get<participantTeamData>(url + `participantsteams/${seasonEventID}/${seasonEventRaceID}/${page}`, this.getHeader());
  }

  getAllParticipantsTeamsObservable(seasonEventID: number, seasonEventRaceID: number, lastProcessed: number, userID: number) {
    return this.http.get<participantTeamData>(url + `allparticipantsteams/${seasonEventID}/${seasonEventRaceID}/${lastProcessed}/${userID}`, this.getHeader());
  }

  getParticipationsObservable(seasonEventRaceID: number) {
    return this.http.get<participationsExtData>(url + `participations/${seasonEventRaceID}`, this.getHeader());
  }

  getParticipationObservable(seasonEventRaceID: number, userID: number) {
    return this.http.get<participationExtData>(url + `participation/${seasonEventRaceID}/${userID}`, this.getHeader());
  }

  getSubstitutionsObservable(seasonEventRaceID: number) {
    return this.http.get<substitutionData>(url + `substitutions/${seasonEventRaceID}`, this.getHeader());
  }

  getRiderResultsObservable(seasonEventID: number) {
    return this.http.get<riderResultsData>(url + `riderresults/${seasonEventID}`, this.getHeader());
  }

  findRider(firstname: string, lastname: string) {
    return this.http.get<findRiderData>(url + `findrider/${firstname}/${lastname}`, this.getHeader());
  }

  getNumberOfSubscriptions() {
    return this.http.get<subscriptionData>(url + `subscriptions`, this.getHeader());
  }

  getSeasonEventPoints() {
    return this.http.get<seasonEventPointsData>(url + `seasonEventPoints`, this.getHeader());
  }

  getSeasonEventResults(seasonEventID: number, limit: number, offset: number) {
    return this.http.get<seasonEventResultsData>(url + `seasoneventresults/${seasonEventID}/${limit}/${offset}`, this.getHeader());
  }

  postUserCredentials(users: UserCredentials[]) {
    return this.http.post<postUserCredentials>(url + 'usercredentials', {
      users
    }, this.getHeader());
  }

  postParticipantTeam(riders: ParticipantRider[]) {
    return this.http.post<postParticipantTeam>(url + 'participantteam', {
      riders
    }, this.getHeader());
  }

  postSubstitution(userID: number, seasonEventID: number,substitutions: Substitution[]) {
    return this.http.post<postSubstitution>(url + 'substitution', {
      userID,
      seasonEventID,
      substitutions
    }, this.getHeader());
  }

  /*deleteSubstitution(substitutions: Substitution[]) {
    return this.http.post<deleteSubstitution>(url + 'delete_substitutions', {
      substitutions
    }, this.getHeader());
  }*/

  postSeasonEventRaces(subscribeEndDate: String, stages: Stage[]) {
    return this.http.post<postSeasonEventRaces>(url + 'seasoneventraces', {
      stages,
      subscribeEndDate
    }, this.getHeader());
  }

  postRaces(races: Race[]) {
    return this.http.post<postRaces>(url + 'races', {
      races
    }, this.getHeader());
  }

  postRule(rule: Rule) {
    return this.http.post<postRule>(url + 'rule', {
      rule
    }, this.getHeader());
  }

  postRiderResults(stageResults: RiderResult[], GCResults: RiderResult[], pointResults: RiderResult[], mountainResults: RiderResult[]) {
    return this.http.post<postRiderResults>(url + 'riderresults', {
      stageResults,
      GCResults,
      pointResults,
      mountainResults
    }, this.getHeader());
  }

  postTeamResults(stageTeamResults: TeamResult[]) {
    return this.http.post<postTeamResults>(url + 'teamresults', {
      stageTeamResults,
    }, this.getHeader());
  }

  postProcessRequest(eventID: number, seasonEventID: number, seasonEventRaceID: number, lastRace: number) {
    console.log("last race " + lastRace)
    return this.http.post<postRiderResults>(url + 'processresults', {
      eventID,
      seasonEventID,
      seasonEventRaceID,
      lastRace
    }, this.getHeader());
  }

  postParticipation(participation: ParticipationExt) {
    return this.http.post<postParticipation>(url + 'participation', {
      participation
    }, this.getHeader());
  }

  postNotification(title: string, message: string, email: boolean, android: boolean) {
    return this.http.post<postNotification>(url + 'notification', {
      title,
      message,
      email,
      android
    }, this.getHeader());
  }

  postNewRider(lastname: string, firstname: string, birthdate: string, country: string, uciid: number, category: string, teamID: number, seasonID: number) {
    return this.http.post<postNewRider>(url + 'newrider', {
      lastname,
      firstname,
      birthdate,
      country,
      uciid,
      category,
      teamID,
      seasonID
    }, this.getHeader());
  }

  postSeasonRider(riderID: number, teamID: number, seasonID: number) {
    return this.http.post<postNewRider>(url + 'newseasonrider', {
      riderID,
      teamID,
      seasonID
    }, this.getHeader());
  }
}
