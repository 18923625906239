import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs';

import { UserService, url } from './user.service';

interface versionData {
  version: string
}

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(
    private userService: UserService,
    private http: HttpClient
  ) { 

  }

  getHeader() {
    let token = this.userService.getToken();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: token
      })
    };

    return httpOptions
  }

  getCurrentVersion() {
    return this.http.get<versionData>(url + 'version', this.getHeader())
  }

  downloadAPK() {
		return this.http.get(url + 'loopingprono/apk', { 
      responseType: 'blob',
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({
        Authorization: this.userService.getToken()
      })
    })
  }

  downloadZIP(){
		return this.http.get(url + 'loopingprono/zip', { 
      responseType: 'blob',
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({
        Authorization: this.userService.getToken()
      })
    })
  }
}
